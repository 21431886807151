import React, { FunctionComponent } from 'react';
import Faqs from "./index";
import Heading from "../Heading";

type CategorizedFaqsListProps = {
    items: any[],
};

const CategorizedFaqsList: FunctionComponent<CategorizedFaqsListProps> = ({items, ...props}) => {

    return (
        <>
            <div className={'flex justify-center w-full'}>
                <div className={'w-2/3'}>
                    <Heading type='h5' className={'font-semibold mb-4'}>Select category</Heading>
                    <select name="" id="">
                        <option value="freelance">Freelance</option>
                        <option value="sro">S.R.O. (Limited Liability)</option>
                        <option value="non-profits">Non-profits</option>
                    </select>
                    <div className={'py-12'}>
                        <Faqs items={items}/>
                    </div>
                </div>
            </div>

        </>
    );
};

export default CategorizedFaqsList;
