import React, { FunctionComponent } from 'react';
import Section from "../components/Section";
import Hero from "../components/Hero";
import FaqImage from "../assets/images/faq.svg";
import CategorizedFaqsList from "../components/Faqs/CategorizedFaqsList";

type FaqsProps = {
};

const FaqsPage: FunctionComponent<FaqsProps> = (props) => {

    return (
        <>
            <Section className='py-8' fullWidth>
                <div>
                    <Hero
                        headline={'Frequently asked questions'}
                        heroImage={FaqImage}
                        outerContainer
                    />
                </div>
            </Section>
            <Section className={'py-12'}>
                <div className={''}>
                    <CategorizedFaqsList items={[]}/>
                </div>
            </Section>
        </>
    );
};

export default FaqsPage;
